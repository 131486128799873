/* eslint-disable camelcase */
export const screenKeys = ['setup', 'setup-models', 'input-levels', 'edit-plans', 'edit-model', 'input-conditions', 'run-simulation'] as const;
export type ScreenKey = typeof screenKeys[number];
export const screenIndices = [0, 1, 2, 3, 4, 5, 6] as const;
export type ScreenIndex = typeof screenIndices[number];

export const disabledScreenOnCreateModelMode: ScreenKey[] = ['input-levels'];
export const disabledScreenIndicesOnCreateModelMode = screenKeys.map((s, i) => (disabledScreenOnCreateModelMode.includes(s) ? i : null)).filter((i) => i !== null) as ScreenIndex[];

export interface Notice {
  agreement: boolean;
  backOnStepper: boolean;
}

export interface AnalysisLocalState {
  createdAt: Date;
  prevScreenIndex: ScreenIndex;
  screenIndex: ScreenIndex;
  setScreenIndex: (newScreenIndex: ScreenIndex) => void;
  setScreenIndexByKey: (screenKey: ScreenKey) => void;
  notice: Notice;
  setNotice: (newNotice: Notice) => void;
}
