import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import { THEME_COLOR } from 'src/constants';

export const lightTheme = createTheme({
  palette: {
    primary: {
      main: '#010112',
    },
    secondary: {
      main: THEME_COLOR,
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#F9FAFB',
      paper: '#F3F4F6',
    },
    warning: {
      main: '#F02C2D',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        color: 'inherit',
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
        color: 'inherit',
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&$active': {
            color: THEME_COLOR,
          },
        },
      },
    },
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#010112',
    },
    secondary: {
      main: THEME_COLOR,
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#131319',
      paper: '#020214',
    },
    warning: {
      main: '#F02C2D',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        color: 'inherit',
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
        color: 'inherit',
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&$active': {
            color: THEME_COLOR,
          },
        },
      },
    },
  },
});
