import React, { useContext, useEffect, useMemo, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ThemeProvider, Theme } from '@mui/material/styles';
import { EmotionCache } from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { isServer } from 'src/utils/isServer';
import createEmotionCache from 'src/utils/createEmotionCache';
import { lightTheme, darkTheme } from './theme';

const clientSideEmotionCache = createEmotionCache();

export const themeStateKey = 'themeState-0.0.3';

interface ThemeState {
  theme: Theme;
  setTheme: (theme: Theme) => void;
}

const initialState: ThemeState = {
  theme: lightTheme,
  setTheme: () => { return; },
};

export const ThemeContext = React.createContext<ThemeState>(initialState);

interface Props {
  children: React.ReactNode;
  emotionCache: EmotionCache;
}

export default function Provider({ children, emotionCache = clientSideEmotionCache }: Props): React.ReactElement {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const initialTheme = useMemo(() => (prefersDarkMode ? darkTheme : initialState.theme), [prefersDarkMode]);
  const [theme, setTheme] = useState(initialTheme);

  const localThemeState = useMemo(() => {
    const localData = isServer ? null : localStorage.getItem(themeStateKey);
    return localData && (JSON.parse(localData) as ThemeState);
  }, [isServer]);

  const themeState = useMemo((): ThemeState => {
    return {
      theme,
      setTheme,
    };
  }, [theme]);

  useEffect(() => {
    if (localThemeState) {
      const targetTheme = localThemeState.theme.palette.mode === 'light' ? lightTheme : darkTheme;
      setTheme(targetTheme);
    }
  }, [localThemeState]);

  useEffect(() => {
    try {
      !isServer && localStorage.setItem(themeStateKey, JSON.stringify(themeState));
    } catch (error) {}
  }, [themeState]);

  return (
    <CacheProvider value={emotionCache}>
      <ThemeProvider theme={theme}>
        <ThemeContext.Provider value={themeState}>{children}</ThemeContext.Provider>
      </ThemeProvider>
    </CacheProvider>
  );
}


export function useThemeContext(): ThemeState {
  return useContext(ThemeContext);
}
