type Unit = 'seconds' | 'minutes' | 'hours' | 'days';

export function past(time: number | Date, value: number, unit: Unit = 'days'): boolean {
  const now = new Date();
  const givenTime = new Date(time);
  let c = 1000;

  if (unit === 'minutes') {
    c = 60000;
  } else if (unit === 'hours') {
    c = 360000;
  } else if (unit === 'days') {
    c = 86400000;
  }

  return ((now.getTime() - givenTime.getTime()) / c >= value);
}
