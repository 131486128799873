/* eslint-disable camelcase */
import { AnalysisResult,KpiPayload } from 'types';
import { parameters, condition } from 'src/app/parameters';
import { ProcessGetResponse, ProcessDeleteResponse, ProcessPostResponse, ProcessPutResponse } from 'src/apiInApp/KeyResponseType';
import { ErrorKey } from 'types/GeneralTypes';

export const siteKeys = ['sapporo', 'matsumoto', 'tokyo', 'nagoya', 'osaka', 'miho', 'tosashimizu', 'shimonoseki', 'fukuoka', 'kagoshima', 'experiment'] as const;
export type Site = typeof siteKeys[number];

export const materialKeys = ['wood', 'concrete', 'traditional', 'experiment'] as const;
export type Material = typeof materialKeys[number];

export type AnalysisCondition = typeof condition;
export const commonProgramKey = 'common';
export type Program = Exclude<keyof AnalysisCondition, typeof commonProgramKey>;
export const programs = Object.keys(condition).filter((e) => e !== commonProgramKey) as Program[];
export const getSpaceTypes = (program: Program): string[] => {
  if (!condition || !condition[program]) return [];
  return Object.keys(condition[program]).concat(Object.keys(condition[commonProgramKey]));
};
export const isProgram = (tested: any): tested is Program => {
  return tested !== undefined;
};

export const levelKey = (value: number): string => String(value); // Need to be just number string for sorting
export const programKey = 'program';
export const spaceTypeKey = 'space_type';
export const wwrKey = 'wwr';
export const shadingKey = 'shading';
export const perWwrKeyRegrex = /^wwr[0-9]+$/;
export const perWwrKey = (value: number): string => wwrKey + String(value);
export const shadingRegrex = /^shading[0-9]+$/;
export const getShadingKey = (value: number): string => shadingKey + String(value);

export type ConditionInputs = {
  [programKey]: Program;
  [spaceTypeKey]: string;
  [wwrKey]: number;
};

export type FormInputs = ConditionInputs & {
  [key: string]: number;
};

export interface ConditionProperty {
  envelope: string;
  base_wwr: number;
  cooling_setpt: number;
  heating_setpt: number;
  hvac: string;
  ef_ach: number;
  ef_deltapressure: number;
  cooling_cop?: number;
  heating_cop?: number;
}

export type AnalysisPoint = [number, number];
export type AnalysisLine = [AnalysisPoint, AnalysisPoint];
export interface AnalysisZone extends ConditionProperty {
  name: string;
  coordinates: AnalysisPoint[];
  exteriorWalls: number[];
  [programKey]: Program;
  [spaceTypeKey]: string;
  [wwrKey]: (number | null)[];
  [shadingKey]: (number | null)[];
}
export const zoneNameSplitter = '_zone';
export const zoneName = (floor: number, index: number): string => `${floor}${zoneNameSplitter}${index}`;

export interface AnalysisFloorGeometry {
  floor: number;
  level: number;
  height: number;
  image?: string;
  zones?: AnalysisZone[];
  warning_lines?: AnalysisLine[];
}

export const geometryVersion = 0.1;
export interface AnalysisZoneGeometry {
  version: number;
  geometries: AnalysisFloorGeometry[];
}

export type AnalysisParameter = typeof parameters;

export interface Threshold {
  min: number;
  max: number;
}
export interface Thresholds {
  [key: string]: Threshold;
}

export interface Others {
  escalator?: number;
  elevator?: number;
  exterior_lighting?: number;
}

export interface SpaceTypeSetting {
  cooling_setpt: number;
  heating_setpt: number;
  ppl_density: number;
  oa_person: number;
  oa_area: number;
  smallpower: number;
  lighting: number;
  hvac: string;
  cooling_cop?: number;
  heating_cop?: number;
  ef_ach: number;
  ef_deltapressure: number;
}

export interface DaySchedule {
  schedule: number[];
}

type WeekDay = 'weekday' | 'saturday' | 'sunday' | 'coolingday' | 'heatingday';

export type WeekSchedule = {
  [key in WeekDay]: DaySchedule;
};

type ScheduleType = 'occupancy' | 'hvac' | 'lighting' | 'equipment';

export type ScheduleUnit = {
  [key in ScheduleType]: WeekSchedule;
};

export type ScheduleSetting = {
  [key in Program]: ScheduleUnit;
};

export type EnvelopeSetting = {
  [key in Material]: ConstructionSetting;
};

export type ConstructionType = 'wall' | 'internal_wall' | 'roof' | 'floor' | 'ceiling';

export type ConstructionSetting = {
  wall: OpaqueSetting[];
  internal_wall: OpaqueSetting[];
  roof: OpaqueSetting[];
  floor: OpaqueSetting[];
  ceiling: OpaqueSetting[];
  window: WindowSetting;
};

// export type ConstructionSetting = OpaqueSettings & {
//   window: WindowSetting;
// };

export type OpaqueSettings = {
  [key: string]: OpaqueSetting[];
};

export type Parameters = {
  load: { [key: string]: SpaceTypeSetting };
  schedule: ScheduleSetting;
  envelope: EnvelopeSetting;
  others: OthersSetting;
  airside: AirSideSetting;
  plant: PlantSideSetting;
};

//eppyの求めるフォーマット（最初大文字）にあわせている
export interface OpaqueSetting {
  Name: string;
  Roughness: string; //should be changed to type
  Thickness: number;
  Conductivity: number;
  Density: number;
  Specific_Heat: number;
  error?: boolean;
}

export interface WindowSetting {
  uvalue: number;
  shgc: number;
  vt: number;
}

export interface OthersSetting {
  escalator: number;
  elevator: number;
  exterior_lighting: number;
}

export interface TerminalSetting {
  [key: string]: any;
}

export interface AirLoopSetting {
  [key: string]: any;
}

export interface AirSideSetting {
  [key: string]: any;
}

export interface PlantSideSetting {
  [key: string]: any;
}

export const timeseriesKeys = [
  'Site Outdoor Air Drybulb Temperature',
  'Zone Air Temperature',
  'Zone Air Relative Humidity',
  'Fan Electric Rate',
  'Heating Coil Heating Rate',
  'Lights Electricity Rate',
  'Surface Window Heat Gain Rate',
  'Surface Window Heat Loss Rate',
  'VRF Heat Pump COP',
  'VRF Heat Pump Total Cooling Rate',
  'VRF Heat Pump Total Heating Rate',
  'VRF Heat Pump Part Load Ratio',
  'Zone Electric Equipment Electricity Rate',
] as const;

export type TimeseriesKey = typeof timeseriesKeys[number] | string;

export interface BaseProcess {
  projectId: string;
  description: string;
  site: Site;
  material: Material;
  northAxis: number;
  geometry: AnalysisZoneGeometry;
  parameter: AnalysisParameter;
  timeseriesKeys: TimeseriesKey[];
}

export interface Process extends BaseProcess {
  isNew?: boolean;
  processId: string;
  result?: ProcessResult;
  createdAt?: string;
  updatedAt?: string;
}

export interface ProcessResult{
  processId: string;
  ownerId: string;
  createdAt: string;
  updatedAt?: string;
  kpis: AnalysisResult;
}

export interface ResultPayload{
  processId: string;
  ownerId: string;
  createdAt: string;
  updatedAt?: string;
  kpis: KpiPayload;
}

export type BaseProcessPayload = Omit<BaseProcess, 'geometry' | 'parameter'> & { geometry: string, parameter: string };
export type ProcessPayload = Omit<Process, 'geometry' | 'parameter' | 'result'> & {
  geometry: string;
  parameter: string;
  result: ResultPayload | undefined;
};

export interface ProcessState1 {
  process: Process;
  setProcess: (process: Process) => void;
  setGeometry: (geometry: AnalysisZoneGeometry) => void;
}

export interface ProcessState2 {
  getProcess: () => Promise<ProcessGetResponse | null>;
  deleteProcess: (processId: string) => Promise<ProcessDeleteResponse | null>;
  postProcess: (process: Process) => Promise<ProcessPostResponse | null>;
  putProcess: () => Promise<ProcessPutResponse | null>;
  isLoading: boolean;
  isDeleting: boolean;
  isCreating: boolean;
  isSaving: boolean;
  error: false | ErrorKey;
}

export type ProcessState = ProcessState1 & ProcessState2;
