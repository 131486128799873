import React from 'react';
import { AppProps, NextWebVitalsMetric } from 'next/app';
import CssBaseline from '@mui/material/CssBaseline';
import { EmotionCache } from '@emotion/cache';
import { PRODUCTION_BUILD } from 'src/constants';
import Providers from 'src/Providers';
import * as gtag from 'src/utils/gtag';
import { disableReactDevTools } from 'src/utils/disableReactDevTools';
import 'public/css/global.css';
import 'public/css/markdown.css';
import 'public/css/video.css';

function MyApp(props: AppProps & { emotionCache: EmotionCache }): React.ReactElement {
  const { Component, pageProps, emotionCache } = props;

  if (PRODUCTION_BUILD) {
    disableReactDevTools();
  }

  return (
    <Providers emotionCache={emotionCache}>
      <CssBaseline />
      <Component {...pageProps} />
    </Providers>
  );
}

export function reportWebVitals({ id, name, label, value }: NextWebVitalsMetric): void {
  gtag.event({
    action: name,
    category: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
    label: id,
    value: Math.round(name === 'CLS' ? value * 1000 : value),
  });
}

export default MyApp;
