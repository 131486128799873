import { locales } from './config';
import tJa from './translations/ja';

export const localeKeys = Object.keys(tJa);
export type Locale = typeof locales[number];
export type LocaleKey = keyof typeof tJa;

export type Strings = {
  [key in Locale]: {
    [index in LocaleKey]: string;
  };
}

export function isLocale(tested: string): tested is Locale {
  return locales.some(locale => locale === tested);
}
