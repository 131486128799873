import { useCallback, useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { errorResponse, handleError, isKeyRequestURLIncludesQueryParam, AnalysisResponse } from 'src/utils/api';
import { useKeyRequestData } from './useKeyRequestData';
import { KeyResponses } from './KeyResponseType';
import { KeyRequests, KeyRequestData } from './KeyRequestType';

type ExtractGeneric<Type> = Type extends KeyRequestData<infer X> ? X : undefined;

type KeyRequestOption<K extends keyof KeyRequests, T extends KeyRequests[K]> = {
  data?: Partial<T["data"]>;
  query?: {
    [key: string]: string;
  };
};
type KeyRequestReturn<K extends keyof KeyRequests> = KeyResponses[K] | AnalysisResponse<null>;
type KeyRequest<K extends keyof KeyRequests> = [(args: ExtractGeneric<KeyRequests[K]>, option?: KeyRequestOption<K, KeyRequests[K]>) => Promise<KeyRequestReturn<K>>, boolean];

export function useKeyRequest<K extends keyof KeyRequests>(key: K): KeyRequest<K> {
  const [loading, setLoading] = useState<boolean>(false);
  const { url: requestURL, validate, ...requestData } = useKeyRequestData<K>(key);

  useEffect(() => {
    setLoading(false);
  }, []);

  const keyRequest = useCallback(async (args: ExtractGeneric<KeyRequests[K]>, option?: KeyRequestOption<K, KeyRequests[K]>): Promise<KeyRequestReturn<K>> => {
    setLoading(true);
    // @ts-ignore
    const data0 = requestData?.data ? requestData.data(args) : {};
    const data = { ...data0, ...option?.data };

    let response: AxiosResponse<any>;
    // @ts-ignore
    let url = requestURL(args);

    if (!url) {
      setLoading(false);
      return errorResponse({ status: 500, message: 'Internal Error' });
    }
    if ((requestData.method === 'POST' || requestData.method === 'PUT') && !data) {
      setLoading(false);
      return errorResponse({ status: 500, message: 'Internal Error' });
    }

    if (option?.query) {
      const queryString = Object.keys(option.query).map(key => {
        const value = option.query ? option.query[key] : null;
        return `${key}=${value}`;
      }).join('&');
      url = isKeyRequestURLIncludesQueryParam(url) ? url + '&' + queryString : url + '?' + queryString;
    }

    const requestConfig = { ...requestData, url, data };

    // @ts-ignore
    if (validate && !validate(args, requestConfig)) {
      setLoading(false);
      return errorResponse({ status: 500, message: 'Internal Error' });
    }

    try {
      console.log('[Request]', key, requestConfig);
      response = await axios(requestConfig);
      console.log(`[Response from ${key}]`, response);
      return  { ...response.data, status: response.status, data: response.data.data || true } as KeyResponses[K];
    } catch (error: any) {
      handleError(error);
      return errorResponse(error);
    } finally {
      setLoading(false);
    }
  }, [key, requestData, requestURL, validate]);

  return [keyRequest, loading];
}
