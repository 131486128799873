import { GA_TRACKING_ID, PROD } from 'src/constants';

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string): void => {
  if (!PROD) return;

  // @ts-ignore
  window.gtag('config', GA_TRACKING_ID, {
    page_path: url,
  });
};

interface EventProps {
  action: string;
  category: string;
  label: string;
  value: number;
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }: EventProps): void => {
  if (!PROD) return;

  // @ts-ignore
  window.gtag('event', action, {
    event_category: category,
    event_label: label, // id unique to current page load
    value: value, // values must be integers
    non_interaction: true, // avoids affecting bounce rate.
  });
};
