import { useMemo } from 'react';
import { KeyRequests } from './KeyRequestType';

export function useKeyRequestData<K extends keyof KeyRequests>(key: K): KeyRequests[K] {
  const requestData = useMemo((): KeyRequests => {
    return {
      'user/get': {
        method: 'GET',
        url: () => `/api/auth/req?path=/user`,
      },
    };
  }, []);

  return requestData[key];
}
