import React, { useState, useCallback, useContext, useMemo, useEffect } from 'react';
import { useRouter } from 'next/router';
import { AnalysisLocalState, screenKeys, ScreenKey, ScreenIndex } from 'types';
import { past } from 'src/utils/time';
import { isServer } from 'src/utils/isServer';

export const appStateKey = 'appState-0.4';

const defaultInitialState: AnalysisLocalState = {
  createdAt: new Date(),
  prevScreenIndex: 0,
  screenIndex: 0,
  setScreenIndex: () => { return; },
  setScreenIndexByKey: () => { return; },
  notice: {
    agreement: true,
    backOnStepper: true,
  },
  setNotice: () => { return; },
};

const localData = isServer ? null : localStorage.getItem(appStateKey);
let appLocalState = localData && (JSON.parse(localData) as AnalysisLocalState);

if (appLocalState && past(appLocalState.createdAt, 7, 'days')) {
  localStorage.clear();
  appLocalState = null;
}

const initialState: AnalysisLocalState = appLocalState || defaultInitialState;

export const AnalysisLocalContext = React.createContext<AnalysisLocalState>(initialState);

interface AnalysisLocalProviderProps {
  children: React.ReactNode;
}

export function AnalysisLocalProvider({ children }: AnalysisLocalProviderProps): React.ReactElement {
  const [prevScreenIndex, setPrevScreenIndex] = useState(initialState.prevScreenIndex);
  const [screenIndex, setScreenIndexAction] = useState(initialState.screenIndex);
  const [notice, setNotice] = useState(initialState.notice);
  const router = useRouter();

  const getScreenIndex = useCallback((screenKey: ScreenKey): ScreenIndex | -1 => {
    return screenKeys.indexOf(screenKey) as ScreenIndex | -1;
  }, []);

  const getScreenKey = useCallback((index: ScreenIndex): ScreenKey => {
    return screenKeys[index];
  }, []);

  const setScreenIndex = useCallback(
    (newScreenIndex: ScreenIndex) => {
      router.push({
        href: router.pathname,
        query: {
          ...router.query,
          screen: getScreenKey(newScreenIndex),
        },
      });
      setPrevScreenIndex(screenIndex);
      setScreenIndexAction(newScreenIndex);
    },
    [getScreenKey, router, screenIndex],
  );

  const setScreenIndexByKey = useCallback(
    (screenKey: ScreenKey) => {
      const index = getScreenIndex(screenKey);
      if (index !== -1) {
        setPrevScreenIndex(screenIndex);
        setScreenIndexAction(index);
      } else {
        console.error('Screen key is incorrect.');
      }
    },
    [getScreenIndex, screenIndex],
  );

  const appState = useMemo((): AnalysisLocalState => {
    return {
      createdAt: initialState.createdAt,
      prevScreenIndex,
      screenIndex,
      setScreenIndex,
      setScreenIndexByKey,
      notice,
      setNotice,
    };
  }, [prevScreenIndex, screenIndex, setScreenIndex, setScreenIndexByKey, notice]);

  useEffect(() => {
    try {
      localStorage.setItem(appStateKey, JSON.stringify(appState));
      // const dateTime = /\d\d:\d\d:\d\d/.exec(new Date().toString());
      // dateTime && console.log('[' + dateTime[0] + ']', 'Saved app state.', appState);
    } catch (error) {}
  }, [appState]);

  return <AnalysisLocalContext.Provider value={appState}>{children}</AnalysisLocalContext.Provider>;
}

export function useAnalysisLocalContext(): AnalysisLocalState {
  return useContext(AnalysisLocalContext);
}
