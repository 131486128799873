// blank

const ja = {
  // Language
  'en': 'English',
  'ja': '日本語',

  'add-process': 'プロセスを追加',
  'allOn': '24時間稼働',
  'blog': 'ブログ',
  'case': 'ケース',
  'catchphrase': 'Connect Engineering Empower Everyone',
  'compare-results': '結果比較',
  'confirm-delete-process': '一度プロセスを削除すると、元に戻すことができません。プロセスを削除してよろしいですか？',
  'confirm-delete-project': '一度プロジェクトを削除すると、元に戻すことができません。プロジェクトを削除してよろしいですか？',
  'contact-support': 'お問い合わせ',
  'create': '作成',
  'create-at': '作成日時',
  'dashboard': 'ダッシュボード',
  'delete': '削除',
  'setting': '設定',
  'delete-process': 'プロセスを削除',
  'delete-project': 'プロジェクトを削除',
  'detail': '詳細',
  'display': '表示',
  'display-percentage': '%表示',
  'documents': 'ドキュメント',
  'documents-menu': 'ドキュメントメニュー',
  'edit': '編集',
  'energy-consumption': 'エネルギー消費量',
  'error-bad-request': 'エラーが発生しました。',
  'error-unexpected': 'エラーが発生しました。しばらく待ってから、もう一度お試しください。',
  'faqs': 'よくある質問',
  'floor': '階',
  'go-to-app': 'アプリへ移動',
  'go-to-edit': '編集画面へ',
  'go-to-project': 'プロジェクトへ',
  'home': 'ホーム',
  'index': 'インデックス',
  'input-process-description': 'プロセスの説明を入力してください',
  'language': '言語',
  'learn': '学ぶ',
  'leave-monitoring-simulation': '離れる',
  'leave-monitoring-simulation-message': 'モニタリングから離れてよろしいですか？シミュレーションが正常に終了すると、ダッシュボードから結果を確認できます。',
  'list': '一覧',
  'menu': 'メニュー',
  'mode': 'モード',
  'name': '名前',
  'navigation': 'ナビゲーション',
  'no-result': '結果がありません',
  'no-process': 'プロセスがありません',
  'no-projects': 'プロジェクトがありません',
  'none': 'なし',
  'overview': '概要',
  'please-sign-in': 'ログインしてください',
  'please-verify-email': '先にメールアドレスの認証を完了してください。',
  'process': 'プロセス',
  'process-description': '名前',
  'products': 'プロダクト',
  'projects': 'プロジェクト',
  'project-dashboard': 'プロジェクトダッシュボード',
  'pricing': '料金',
  'privacy': 'プライバシー',
  'restart': 'リスタート',
  'result-create-at': '結果作成日時',
  'result-exists': '結果があります',
  'see-all-projects': '全てのプロジェクトを見る',
  'select-base-process': 'ベースにするプロセスを選択してください。',
  'send-email-when-simulation-is-complete-description': '解析終了時にメールで通知する',
  'sign-in': 'ログイン',
  'sign-out': 'ログアウト',
  'sign-up': '新規登録',
  'sub-catchphrase': '建築エネルギーシミュレーションアプリ',
  'success': '成功',
  'success-create-process': '新しいプロセスを作成しました',
  'success-create-project': '新しいプロジェクトを作成しました',
  'success-delete-process': 'プロセスが削除されました',
  'success-delete-project': 'プロジェクトが削除されました',
  'support': 'サポート',
  'slack-community': 'Slack コミュニティ',
  'stay-connected': 'つながる',
  'terms': '利用条件',
  'toggle-theme': 'ライト/ダーク表示を切替える',
  'tooltip-compare-results-button': '結果比較には複数のプロセスを選択してください',
  'try': '使ってみる',
  'updated-at': '更新日時',
  'use-cases': 'ユースケース',
  'user': 'ユーザー',
  'value': '値',
  'version': 'バージョン',
  'watch-video': '動画を見る',
  'zone': 'ゾーン',
  'token': 'トークン',
  'generate-token': 'トークンを生成する',
  'generate-token-info': 'Generate Access Token',

  // meta data
  'index-description': '建築設計におけるエネルギーシミュレーションを簡単、気軽にできるWebアプリ',
  'locale': 'ja_JP',
  'product-title': 'プロダクト',
  'product-description': 'BAUES Analysisで提供しているサービスの一覧です。',
  'use-cases-title': 'ユースケース',
  'use-cases-description': 'BAUES Analysisの活用例を記載しています。',
  'support-title': 'サポート',
  'support-description': 'BAUES Analysisに関して、お困りの場合はこちら。',
  'terms-title': '利用規約',
  'terms-description': 'BAUES Analysisの利用規約はこちら。',
  'app-title': 'BAUES Analysis',
  'app-description': '簡単に建物のエネルギーシミュレーションができるWebアプリ',

  'energy-simulation': 'エネルギーシミュレーション',
  'service-description': '3Dモデルをuploadするだけで、簡単に建物のエネルギー性能を見える化できるサービスです。詳しい内容はドキュメントページを参照ください。',

  'usecase-description': '設計、プレゼン、教育など様々なシーンでエネルギー性能に関する知見を提供します',
  'design-integration': '設計プロセスに組み込んで、エネルギー性能を定量化',
  'better-communication': 'エネルギー性能を見える化して、コミュニケーションを円滑に',
  'education-purpose': '教育に用いることで環境建築をよりわかりやすく、直感的に教えるサポートをします',
  'get-consultation': '建築性能をよりよくするためのアドバイスを受けることができます（予定）',

  'support-top': '何かお困りですか？',
  'how-to-use': '使い方がよくわからない',
  'technical-info': 'シミュレーションに関する詳しい参照資料を知りたい',
  'about-baues': 'BAUESとは何か?',
  'support-form': 'サポートに問い合わせる',
  'contact-prompt': 'こちらのメールアドレスに要件を連絡してください',

  // Documents Navigation
  'introduction': 'イントロダクション',
  'get-started': '基本',
  'installation': 'インストール',
  'prepare-model': 'モデルの準備',
  'basic-flow': '基本的な流れ',
  'tutorial': 'チュートリアル',
  'first-step': 'ファーストステップ',
  'modify-conditions': '解析条件を修正する',
  'api': 'API',
  'router': 'ルーター',

  // Setup Project
  'welcome-message': 'BAUES Analysisへようこそ',
  'welcome-agreement-message': 'BAUES Analysisを始めるには、以下の利用条件に同意いただく必要があります。',
  'term-agree': '同意して、シミュレーションを開始します',

  'start-project': 'プロジェクト開始',
  'create-project': 'プロジェクトの作成',
  'setup': 'セットアップ',
  'setup-models': 'モデルの設定',
  'input-levels': '階高の設定',
  'edit-plans': '平面の編集',
  'edit-model': 'モデルの調整',
  'input-conditions': '解析条件の設定',
  'run-simulation': 'シミュレーション開始',
  'results': '解析結果',
  'upload-grasshopper': 'Grasshopperを用いてアップロード',
  'warning': '警告',
  'dont-show': '以後、表示しない。',
  'cancel': 'キャンセル',

  'reference': 'レファレンス',
  'default-parameter': 'デフォルトパラメーター',
  'energyplus': 'EnergyPlus',
  'epw': '気象データepw',
  'guidelines': 'ガイドライン',
  'model-requirements': 'モデルの要件',
  'contributors': '協力',

  // Screens
  // Common
  'next': '次へ',
  'done': '完了',

  // Setup project
  'project-prompt': 'プロジェクト名を入力してください',
  'project-name': 'プロジェクト名',
  'project-description': 'プロジェクトの説明',
  'project-brief': 'プロジェクトを設定します',
  'site-prompt': 'プロジェクトの敷地と近い地名を選択してください',
  'site': '敷地名',
  'site-brief': '敷地名の気象データを用いてシミュレーションを行います',
  'material-prompt': '建物の構造を選択してください',
  'material': '構造',

  // Setup models
  'import-prompt': '3Dモデル読み込み',
  'import-description': '解析モデル作成に使用する3Dモデルをインポートしてください。.obj/.dae/.stlファイルをインポートすることができます。',
  'import-example': 'テスト用のモデルを使う',
  'import-users': '自分のモデルをインポート',
  'import-model-notification': 'インポートするモデルの注意点',
  'no-thick-wall': '壁厚がないモデルにする',
  'no-gap-near-boundary': '壁の境界部分に小さな隙間を作らない',
  'no-window': '窓はモデリングしない',
  'simple-model': 'モデルを単純化する',
  'select-setup-analysis-model-options': '解析モデルの設定方法を選んでください',
  'create-model': 'モデルの作成',
  'import-model': 'モデルのインポート',
  'create-model-explanation': 'BAUES Analysis上でシンプルなモデルを作成する',
  'import-model-explanation': '自分の３Dモデルを元にモデルを作成する。もしくは用意されているテスト用モデルを使う。',
  'input-plan': '平面形状の入力',
  'direction': '方位',

  'import-button': 'インポート',
  'clear-button': 'モデル消去',
  'delete-zone': 'ゾーン消去',
  'cancel-delete-zone': 'ゾーン消去を解除',
  'clear-model-first': '先にモデルをクリアしてください',
  'add-next-floor': '次の階を追加',
  'next-floor': '次の階へ',
  'previous-floor': '前の階へ',
  'height': '高さ',

  'setup-model-x-error': '0 < x <= 1000の範囲で指定してください',
  'setup-model-y-error': '0 < y <= 1000の範囲で指定してください',
  'setup-model-dx-error': '-500 <= dx <= 500の範囲で指定してください',
  'setup-model-dy-error': '-500 <= dy <= 500の範囲で指定してください',
  'setup-model-height-error': '0 < 高さ <= 1000の範囲で指定してください',

  // Input levels
  'floor-level': '地面から平面の高さ[m]',
  'floor-level-name': 'レベル',
  'floor-plan': '階平面',
  'north-axis': '北方向',

  // Edit plans
  'edit-mode': '編集モード',
  'edit-mode-select': '選択',
  'edit-mode-draw': '追加',
  'snap-mode': 'スナップ位置',
  'snap-mode-end-point': '端点',
  'snap-mode-near': '近接点',
  'snap-mode-middle': '中点',
  'snap-mode-angle': '角度',
  'snap-mode-grid': 'グリッド',
  'floor-height': '階高',
  'delete-layer': '本当に次のレイヤーを削除しますか：',

  // Info, Warning, Alert
  'step-back-warning': '前のステップに戻ることで、それまでインプットしていたデータが消去されることがあります。前のステップに戻ってよろしいでしょうか？',
  'clear-app-state-warning': 'これまでの作業データを全て消去し初めからスタートします。よろしいですか？',
  'edit-plans-info': '現在は平面の変更はできません。確認の上、次に進んでください',
  'edit-model-info': '不要なゾーンが作成されている場合は削除してください。詳しくはドキュメントを参照ください',
  'fail-geosimplify': '平面情報を抜き出すことができませんでした。ドキュメントを参照し、モデルを修正してもう一度アップロードしてみてください',
  'zone-error-alert': 'エラーとなっていたゾーンがあります。もう一度ご確認ください',
  'no-model-alert': 'モデルをインポートしてください',
  'can-not-import-model-alert': 'モデルがロードできませんでした。データをご確認ください。',
  'input-value-alert': '値を入力してください',
  'input-number-alert': '数字を入力してください',
  'input-greater-value-than': '次の値より大きな値を入力してください: ',
  'input-more-than-2-levels': 'レベル間を層として認識します。２レベル以上を入力してください。',
  'window-area-must-be-positive-alert': '窓面積は正の値を入力してください',
  'wwr-is-too-large-alert': '窓面積率が大きすぎます。値は90%以下としてください',
  'error-on-get-results': 'エラー：解析結果の取得できませんでした。前のステップからやり直しますか？',
  'too-large-model-and-ask-scaling': 'モデルが大きすぎます。モデルはmmスケールの可能性があります。自動的に1/1000にスケールダウンすることもできますが、正しく反映されない可能性もあります。1/1000にスケールダウンしますか？',

  'detail-setting': '詳細設定',
  'levels-brief': '高さ情報に基づき解析モデルを作成します。レベル間を層として認識するため、底面から頂部までのレベルを入力してください',
  'program': 'プログラム',
  'program-brief': 'プログラムによって曜日・時間帯におけるエネルギー計算条件が変わります',
  'space-type': '室用途',
  'space-type-brief': '室用途によって照明水準等のパラメータが変わります',
  'wwr': '窓面積率[%]',
  'wwr-brief': '窓面積 / 外壁面積 x 100[%]。',
  'wwr-per-zone': 'ゾーンあたり',
  'per-wall': '壁ごとに入力',
  'per-zone': 'ゾーンごとに入力',
  'learn-more': '詳しく知る',
  'exterior-wall': '外壁',
  'change-input-method': '入力方法を切替え',
  'shading': '庇[m]',
  'shading-brief': '庇の長さを入力します',

  'back-home': 'ホームに戻る',
  'back': '戻る',
  'run': '開始',

  'simulation-prompt': '以下の設定でシミュレーションを開始しますか?',
  'project-summary': 'プロジェクト概要',
  'floor-numbers': '階数',
  'zone-numbers': 'ゾーン数',

  'office': 'オフィス',
  'residential': '住居',
  'retail': '商業施設',
  'school': '教育施設',
  'common': '共通',

  'office_open': 'オープンオフィス',
  'office_closed': '個室オフィス',
  'lift_lobby': 'リフトロビー',
  'main_lobby': 'メインロビー',
  'electrical_mechanical_room': '機械電気室',
  'stairway': '階段',
  'storage': '物置',
  'parking_garage': '駐車場',
  'lift': 'エレベーター',
  'restroom': 'トイレ',
  'corridor': '廊下',
  'kitchen': '台所',
  'bedroom': '寝室',
  'living_room': 'リビング',
  'mall_concourse': 'モールサーキュレーション',
  'restaurant_dining_room': 'レストラン',
  'bar_lounge_cafeteria': 'カフェ',
  'sales_area': '売り場',
  'classroom': '教室',
  'conference_meeting_multipurpose': '講義室',
  'dormitory': '寮',
  'family_dining_area':'食事スペース',
  'lounge_recreation':'ラウンジ/レクリエーションスペース',
  'locker_room':'ロッカールーム',
  'patient_room':'保健室',
  'gymnasium':'体育館',
  'food_preparation':'配膳室',

  'sapporo': '札幌',
  'matsumoto': '松本',
  'tokyo': '東京',
  'nagoya': '名古屋',
  'osaka': '大阪',
  'miho': '美保（鳥取）',
  'tosashimizu': '土佐清水（高知）',
  'shimonoseki': '下関',
  'fukuoka': '福岡',
  'kagoshima': '鹿児島',
  'experiment': '実験用',

  'wood': '木造',
  'concrete': '鉄筋コンクリート造',
  'steel': '鉄骨造',
  'traditional': '伝統工法',
  'brick': 'レンガ造',

  'spaceType': '室用途',
  'coolingSetPt': '冷房設定温度[degC]',
  'heatingSetPt': '暖房設定温度[degC]',
  'pplDensity': '人員密度[人/m2]',
  'oaPerson': '1人あたり外気導入量[L/ppl]',
  'oaArea': '面積あたり外気導入量[L/m2]',
  'smallPower': 'その他電力[W/m2]',
  'lighting': '照明電力密度[W/m2]',
  'coolingCop':'冷房COP',
  'heatingCop':'暖房COP',
  'efAch': '換気扇による換気回数[回/h]',
  'efDeltaPressure': '換気扇の静圧[Pa]',

  'envelope': '外皮設定',
  'schedule': 'スケジュール',
  'close': '閉じる',

  'roof':'屋根',
  'exterior-floor':'床（外気に接する）',
  'outside':'室外側',
  'inside':'室内側',
  'add-material':'層追加',
  'window':'窓性能',
  'uvalue':'U値',
  'shgc':'日射熱取得率',
  'vt':'可視光線透過率',

  'normalConcrete':'普通コンクリート',
  'gypsumBoard':'石膏ボード',
  'plywood':'合板',
  "rawanPlywood":'ラワン材合板',
  'aluminium':'アルミニウム',
  'mildSteel':'鉄（軟鋼）',
  'stainless':'ステンレス',
  'ALC':'ALC',
  'cinderConcrete':'シンダーコンクリート',
  'mortar':'モルタル',
  'slateBoard':'スレートボード',
  'siding':'サイディング',
  'cementBoard':'木毛セメント板',
  'stucco':'漆喰',
  'soil':'土壁',
  'tile':'瓦',
  'cedar':'杉',
  'acrylicBoard':'アクリル板',
  'glass':'ガラス',

  'polystyreneFoam':'押出発泡ポリスチレンフォーム',
  'polyethyleneFoam':'ポリエチレンフォーム',
  'polyurethaneFoam':'ポリウレタンフォーム',
  'glassWool':'グラスウール',

  'geometry': 'ジオメトリー',
  'time-series': '時系列データ',
  'download': 'ダウンロード',
  'hvac': '空調システム',
  'internal_load': '内部負荷',

  'cancel-simulation': '計算中止',
  'view-results': '結果を表示',
  'writing': 'シミュレーションデータ作成中',
  'running': '計算中',
  'completed': '計算終了',

  'january': '1月',
  'february': '2月',
  'march': '3月',
  'april': '4月',
  'may': '5月',
  'june': '6月',
  'july': '7月',
  'august': '8月',
  'september': '9月',
  'october': '10月',
  'november': '11月',
  'december': '12月',

  'total-energy': 'エネルギー消費量',
  'total-energy-consumption': '総エネルギー消費量',
  'heat-balance': '熱収支',
  'heat-gain': '熱取得',
  'heat-removal': '熱損失',

  'Heating': '暖房',
  'Cooling': '冷房',
  'Interior Lighting': '室内照明',
  'Exterior Lighting': '屋外照明',
  'Interior Equipment': 'OA機器',
  'Exterior Equipment': 'エレベーター/エスカレーター',
  'Fans': '搬送動力',
  'Pump': 'ポンプ',
  'Ventilation(Simple)': '換気設備',

  'Total': 'Total',
  'North (315 to 45 deg)': '北面',
  'East (45 to 135 deg)': '東面',
  'South (135 to 225 deg)': '南面',
  'West (225 to 315 deg)': '西面',
  'Above Ground Wall Area [m2]': '壁面面積[m2]',
  'Above Ground Window-Wall Ratio [%]': '窓面積率[%]',

  'Total Site Energy': '建物全体',
  'Total Energy [kWh]': 'エネルギー消費量 [kWh]',
  'Energy Per Total Building Area [kWh/m2]': 'エネルギー消費量/建物全体面積 [kWh/m2]',
  'Energy Per Conditioned Building Area [kWh/m2]': 'エネルギー消費量/空調空間全体面積 [kWh/m2]',

  'Facility [Hours]': '建物全体',
  'Time Not Comfortable Based on Simple ASHRAE 55-2004': 'ASHRAE 55-2004',
  'Time Setpoint Not Met During Occupied Cooling': '冷房中のUnmet Hour[hr]',
  'Time Setpoint Not Met During Occupied Heating': '暖房中のUnmet Hour[hr]',

  'energy_consumption[kWh]': 'エネルギー消費量[kWh]',
  'LPD[W/m2]': '照明電力密度[W/m2]',
  'EUI[kWh/m2]': 'エネルギー消費密度[kWh/m2]',
  'window_heat_gain[W/m2]': '窓からの日射熱取得強度[W/m2]',
  'unmet_hours_cooling[hr]': '冷房中のUnmet Hour[hr]',
  'unmet_hours_heating[hr]': '暖房中のUnmet Hour[hr]',

  'Window Heat Addition [kWh]': '窓からの日射熱取得 [kWh]',
  'HVAC Terminal Unit Sensible Air Heating [kWh]': '外気処理による暖房[kWh]',
  'People Sensible Heat Addition [kWh]': '人体発熱 [kWh]',
  'HVAC Input Heated Surface Heating [kWh]': 'HVAC Input Heated Surface Heating [kWh]',
  'Lights Sensible Heat Addition [kWh]': '照明発熱 [kWh]',
  'Interzone Air Transfer Heat Addition [kWh]': 'Interzone Air Transfer Heat Addition [kWh]',
  'Opaque Surface Conduction and Other Heat Addition [kWh]': '壁面の熱伝導による熱取得 [kWh]',
  'Equipment Sensible Heat Addition [kWh]': 'OA機器による発熱 [kWh]',
  'HVAC Zone Eq & Other Sensible Air Heating [kWh]': '空調端末機器による暖房[kWh]',
  'Infiltration Heat Addition [kWh]': 'すきま風による熱取得 [kWh]',

  'Window Heat Removal [kWh]': '窓からの熱損失 [kWh]',
  'HVAC Terminal Unit Sensible Air Cooling [kWh]': '外気処理による冷房[kWh]',
  'Infiltration Heat Removal [kWh]': 'すきま風による熱損失 [kWh]',
  'HVAC Input Cooled Surface Cooling [kWh]': 'HVAC Input Cooled Surface Cooling [kWh]',
  'HVAC Zone Eq & Other Sensible Air Cooling [kWh]': '空調端末機器による冷房[kWh]',
  'Interzone Air Transfer Heat Removal [kWh]': 'Interzone Air Transfer Heat Removal [kWh]',
  'Opaque Surface Conduction and Other Heat Removal [kWh]': '壁面の熱伝導による熱損失 [kWh]',
  'Equipment Sensible Heat Removal [kWh]': 'Equipment Heat Removal [kWh]',

  'select-zone': 'ゾーンを選択してください',
  'summary': 'サマリー',
  'colorMap': 'カラーマップ',
  'zoneDetail': 'ゾーン詳細',
};

export default ja;
