import React, { useState, useContext, useMemo } from 'react';

interface FormState {
  projectName: string;
  setProjectName: (projectName: string) => void;
  projectDescription: string;
  setProjectDescription: (projectDescription: string) => void;
}

export const initialState: FormState = {
  projectName: '',
  setProjectName: () => { return; },
  projectDescription: '',
  setProjectDescription: () => { return; },
};

export const FormContext = React.createContext<FormState>(initialState);

interface FormProviderProps {
  children: React.ReactNode;
}

export function FormProvider({ children }: FormProviderProps): React.ReactElement {
  const [projectName, setProjectName] = useState(initialState.projectName);
  const [projectDescription, setProjectDescription] = useState(initialState.projectDescription);

  const appTempState = useMemo((): FormState => {
    return {
      projectName,
      setProjectName,
      projectDescription,
      setProjectDescription,
    };
  }, [projectDescription, projectName]);

  return <FormContext.Provider value={appTempState}>{children}</FormContext.Provider>;
}

export function useFormContext(): FormState {
  return useContext(FormContext);
}
