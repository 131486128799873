import React, { useState, useContext, useMemo, useEffect } from 'react';
import { useUser } from '@auth0/nextjs-auth0';
import { useKeyRequest } from 'src/api/keyRequests';

interface CurrentUser {
  id: string;
  new?: boolean;
}

interface AnalysisUserState {
  currentUser: CurrentUser;
  currentUserIsLoading: boolean;
  setCurrentUser: (currentUser: CurrentUser) => void;
}

const defaultInitialState: AnalysisUserState = {
  currentUser: {
    id: '',
  },
  currentUserIsLoading: false,
  setCurrentUser: () => { return; },
};

const initialState: AnalysisUserState = defaultInitialState;

export const AnalysisUserContext = React.createContext<AnalysisUserState>(initialState);

interface AnalysisUserProviderProps {
  children: React.ReactNode;
}

export function AnalysisUserProvider({ children }: AnalysisUserProviderProps): React.ReactElement {
  const { user } = useUser();
  const [currentUser, setCurrentUser] = useState(initialState.currentUser);
  const [userReq, currentUserIsLoading] = useKeyRequest('user/get');

  useEffect(() => {
    async function fetchUser() {
      const response = await userReq(undefined);
      if (response?.data) {
        setCurrentUser({ id: response.data.user_id, new: response.data.new });
      }
    }

    if (user) {
      fetchUser();
    }
  }, [user]);

  const analysisState = useMemo((): AnalysisUserState => {
    return {
      currentUser,
      currentUserIsLoading,
      setCurrentUser,
    };
  }, [currentUser, currentUserIsLoading]);

  return <AnalysisUserContext.Provider value={analysisState}>{children}</AnalysisUserContext.Provider>;
}

export function useAnalysisUserContext(): AnalysisUserState {
  return useContext(AnalysisUserContext);
}
