export const PROD = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF === 'prod';
export const STAGING = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF === 'staging';
export const TEST = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF === 'test';
export const DEV = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF === 'master';
export const PRODUCTION_BUILD = process.env.NODE_ENV === 'production';
export const BASIC_AUTH_REQUIRED = PRODUCTION_BUILD && !PROD;

export const HOST = PRODUCTION_BUILD ? (PROD ? process.env.NEXT_PUBLIC_HOST
  : (STAGING ? process.env.NEXT_PUBLIC_STAGING_HOST : (TEST ? process.env.NEXT_PUBLIC_TEST_HOST : process.env.NEXT_PUBLIC_DEV_HOST)))
  : process.env.NEXT_PUBLIC_HOST;

export const API_HOST = PRODUCTION_BUILD ? (PROD ? process.env.NEXT_PUBLIC_API_HOST
  : (STAGING ? process.env.NEXT_PUBLIC_STAGING_API_HOST : (TEST ? process.env.NEXT_PUBLIC_TEST_API_HOST : process.env.NEXT_PUBLIC_DEV_API_HOST)))
  : process.env.NEXT_PUBLIC_API_HOST;

export const WEBSOCKET_HOST = PRODUCTION_BUILD ? (PROD ? process.env.NEXT_PUBLIC_WEBSOCKET_HOST
  : (STAGING ? process.env.NEXT_PUBLIC_STAGING_WEBSOCKET_HOST : (TEST ? process.env.NEXT_PUBLIC_TEST_WEBSOCKET_HOST : process.env.NEXT_PUBLIC_DEV_WEBSOCKET_HOST)))
  : process.env.NEXT_PUBLIC_WEBSOCKET_HOST;

export const AUTH0_BASE_URL = STAGING ? process.env.STAGING_AUTH0_BASE_URL : process.env.AUTH0_BASE_URL;

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID;

export const SITE_NAME = 'BAUES Analysis';
export const SHORT_NAME = 'Analysis';
export const TWITTER_ACCOUNT = '@BAUES_Analysis';
export const OG_TITLE_SUFFIX = ` | ${SITE_NAME}`;
export const THEME_COLOR = '#1D4ED8';
