import { useRouter } from 'next/router';
import strings from './strings';
import { defaultLocale } from './config';
import { LocaleKey, Locale } from './types';
import { useCallback } from 'react';

interface I18n {
  t: (key: LocaleKey) => string;
  reverseT: (value: string) => string;
}

export default function useI18n(): I18n {
  const { locale } = useRouter();

  const t = useCallback(
    (key: LocaleKey): string => {
      if (!locale) {
        console.warn('Locale not found.');
        return key;
      }

      if (!strings[locale as Locale][key]) {
        console.warn(`Translation '${key}' for locale '${locale}' not found.`);
        return key;
      }

      return strings[locale as Locale][key] || strings[defaultLocale][key] || key;
    },
    [locale],
  );

  const reverseT = useCallback((value: string): string => {
    const obj = locale === 'en' ? strings.en : strings.ja;

    const result = Object.keys(obj).filter((key) => {
      // @ts-ignore
      return obj[key] === value;
    });

    return result.length > 0 ? result[0] : value;
  }, [locale]);

  return {
    t,
    reverseT,
  };
}
