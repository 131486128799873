import useI18n from './useI18n';
import { LocaleKey, Locale, isLocale, localeKeys } from './types';
import { locales } from './config';

export {
  useI18n,
  locales,
  isLocale,
  localeKeys,
};

export type {
  LocaleKey,
  Locale,
};