import { Strings } from './types';
import tEn from './translations/en';
import tJa from './translations/ja';

const strings: Strings = {
  en: tEn,
  ja: tJa,
};

export default strings;
