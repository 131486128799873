import ja from './ja';

const en: typeof ja = {
  // Language
  'en': 'English',
  'ja': '日本語',

  'add-process': 'Add process',
  'allOn': 'All on',
  'blog': 'Blog',
  'case': 'Case',
  'catchphrase': 'Connect Engineering Empower Everyone',
  'compare-results': 'Compare Results',
  'confirm-delete-process': 'Once you delete a process, there is no going back. Will you delete it anyway?',
  'confirm-delete-project': 'Once you delete a project, there is no going back. Will you delete it anyway?',
  'contact-support': 'Contact support',
  'create': 'Create',
  'create-at': 'Created At',
  'dashboard': 'Dashboard',
  'delete': 'Delete',
  'setting': 'Setting',
  'delete-process': 'Delete process',
  'delete-project': 'Delete project',
  'detail': 'Detail',
  'display': 'Display',
  'display-percentage': 'Display %',
  'documents': 'Documents',
  'documents-menu': 'Documents Menu',
  'edit': 'Edit',
  'energy-consumption': 'Energy Consumption',
  'error-bad-request': 'An Error occurred. ',
  'error-unexpected': 'An Error occurred. Please wait a moment and try again.',
  'faqs': 'FAQs',
  'floor': 'Floor',
  'go-to-app': 'Go to app',
  'go-to-edit': 'Go to edit',
  'go-to-project': 'Go to project',
  'home': 'Home',
  'index': 'Index',
  'input-process-description': 'Input process description',
  'language': 'Language',
  'learn': 'Learn',
  'leave-monitoring-simulation': 'Leave',
  'leave-monitoring-simulation-message': 'Are you sure you want to leave simulation? If the simulation is completed successfully, you can check result from dashboard.',
  'list': 'List',
  'menu': 'Menu',
  'mode': 'Mode',
  'name': 'Name',
  'navigation': 'Navigation',
  'no-result': 'No result',
  'no-process': 'No process',
  'no-projects': 'No projects',
  'none': 'None',
  'overview': 'Overview',
  'please-sign-in': 'Please sign in',
  'please-verify-email': 'Please verify your email first',
  'process': 'Process',
  'process-description': 'Description',
  'products': 'Products',
  'projects': 'Projects',
  'project-dashboard': 'Project Dashboard',
  'pricing': 'Pricing',
  'privacy': 'Privacy',
  'restart': 'Restart',
  'result-create-at': 'Result created at',
  'result-exists': 'Result exists',
  'see-all-projects': 'See all projects',
  'select-base-process': 'Select base process',
  'send-email-when-simulation-is-complete-description': 'Send email when simulation is complete',
  'sign-in': 'Sign In',
  'sign-out': 'Sign Out',
  'sign-up': 'Sign Up',
  'sub-catchphrase': 'Building Energy Simulation App',
  'success': 'Success',
  'success-create-process': 'New process was successfully created',
  'success-create-project': 'New project was successfully created',
  'success-delete-process': 'Process was successfully deleted',
  'success-delete-project': 'Project was successfully deleted',
  'support': 'Support',
  'slack-community': 'Slack community',
  'stay-connected': 'Stay Connected',
  'terms': 'Terms',
  'toggle-theme': 'Toggle light/dark theme',
  'tooltip-compare-results-button': 'Select multiple processes to compare results',
  'try': 'Try Now',
  'updated-at': 'Updated At',
  'use-cases': 'Use Cases',
  'user': 'User',
  'value': 'Value',
  'version': 'Version',
  'watch-video': 'Watch video',
  'zone': 'Zone',
  'token': 'Token',
  'generate-token': 'Generate Access Token',
  'generate-token-info': 'Generate Access Token',


  // meta data
  'index-description': 'Web application to help performance driven building design',
  'locale': 'en_US',
  'product-title': 'Products',
  'product-description': 'BAUES Analysis is providing web application to help building energy simulation',
  'use-cases-title': 'Use Cases',
  'use-cases-description': 'These are example use cases BAUES Analysis could help',
  'support-title': 'Support',
  'support-description': 'See this page if you have any problems',
  'terms-title': 'Terms',
  'terms-description': 'This page summarizes terms and conditions',
  'app-title': 'BAUES Analysis',
  'app-description': 'Web application to help user to run building energy simulation easily',

  'energy-simulation': 'Energy Simulation',
  'service-description': 'This is the web application which help user to run energy simulation easily.',

  'usecase-description': 'Provide energy performance information for design, presentation and education etc',
  'design-integration': 'Help improving design by quantifying energy performance',
  'better-communication': 'Improve communication with client by effective visualization',
  'education-purpose': 'Help student to learn building performance design by intuitive user interface',
  'get-consultation': 'We help better building performance design by consulting(Pro plan only)',

  'support-top': 'What can we help you with?',
  'how-to-use': 'Don\'t know how to use',
  'technical-info': 'Want to know detail info regarding to simulation',
  'about-baues': 'What is BAUES?',
  'support-form': 'Can\'t find your issue?',
  'contact-prompt': 'Please send us message by following address',

  // Documents Navigation
  'introduction': 'Introduction',
  'get-started': 'Get Started',
  'installation': 'Installation',
  'prepare-model': 'Create Model',
  'basic-flow': 'Basic Flow',
  'tutorial': 'Tutorial',
  'first-step': 'First Step',
  'modify-conditions': 'Modify Conditions',
  'api': 'API',
  'router': 'Router',

  // Setup Project
  'welcome-message': 'Welcome to BAUES Analysis',
  'welcome-agreement-message': 'To continue, please review and agree to BAUES Analysis\'s Terms of Service below.',
  'term-agree': "I agree. Let's start simulation",

  'start-project': 'Project Start',
  'create-project': 'Create new project',
  'setup': 'Setup',
  'setup-models': 'Setup Models',
  'input-levels': 'Input Levels',
  'edit-plans': 'Edit Plans',
  'edit-model': 'Adjust Model',
  'input-conditions': 'Input Conditions',
  'run-simulation': 'Run Simulation',
  'results': 'Results',
  'upload-grasshopper': 'Upload Grasshopper',
  'warning': 'warning',
  'dont-show': 'Do not show this again',
  'cancel': 'cancel',

  'reference': 'Reference',
  'default-parameter': 'Default Parameter',
  'energyplus': 'EnergyPlus',
  'epw': 'EPW',
  'guidelines': 'Guidelines',
  'model-requirements': 'Model Requirements',
  'contributors': 'Contributors',

  // Screens
  // Common
  'next': 'Next',
  'done': 'Done',

  // Setup project
  'project-prompt': 'Input your project name',
  'project-name': 'Project Name',
  'project-description': 'Project Description',
  'project-brief': 'Start project',
  'site-prompt': 'Choose site for simulation',
  'site': 'Site',
  'site-brief': 'Site meteorological data is used for simulation',
  'material-prompt': 'Choose structural material of your building',
  'material': 'Material',

  // Setup models
  'import-prompt': 'Import your model',
  'import-description': 'Import your 3D model data to create analysis model. .obj .dae .stl are supported.',
  'import-example': 'USE EXAMPLE MODEL',
  'import-users': 'IMPORT MY OWN MODEL',
  'import-model-notification': 'Import model notification',
  'no-thick-wall': 'No thick wall',
  'no-gap-near-boundary': 'No gap exists near boundary',
  'no-window': 'No window',
  'simple-model': 'Simple model',
  'select-setup-analysis-model-options': 'Select setup model options',
  'create-model': 'Create model',
  'import-model': 'Import model',
  'create-model-explanation': 'Create a simple model on BAUES Analysis',
  'import-model-explanation': 'Import your 3D model or use built-in example model',
  'input-plan': 'Input plan',
  'direction': 'Direction of relative north',

  'import-button': 'Import',
  'clear-button': 'Clear',
  'delete-zone': 'Delete zone',
  'cancel-delete-zone': 'Cancel delete zone',
  'clear-model-first': 'Please clear model first',
  'add-next-floor': 'Add next floor',
  'next-floor': 'Next floor',
  'previous-floor': 'Previous floor',
  'height': 'Height',

  'setup-model-x-error': 'Please specify 0 < x <= 1000',
  'setup-model-y-error': 'Please specify 0 < y <= 1000',
  'setup-model-dx-error': 'Please specify -500 <= dx <= 500',
  'setup-model-dy-error': 'Please specify -500 <= dy <= 500の',
  'setup-model-height-error': 'Please specify 0 < height <= 1000',

  // Input levels
  'floor-level': 'Floor Levels[m]',
  'floor-level-name': 'Level',
  'floor-plan': 'floor plan',
  'north-axis': 'North axis',

  // Edit plans
  'edit-mode': 'Edit Mode',
  'edit-mode-select': 'Select',
  'edit-mode-draw': 'Add',
  'snap-mode': 'Snap',
  'snap-mode-end-point': 'EndPoint',
  'snap-mode-near': 'Near',
  'snap-mode-middle': 'Middle',
  'snap-mode-angle': 'Angle',
  'snap-mode-grid': 'Grid',
  'floor-height': 'FloorHeight',
  'delete-layer': 'Are you sure you want to delete the following layer?',

  // Warning
  'step-back-warning': 'You are trying to return previous step. This may cause current step data to be deleted. Are you sure you want to proceed?',
  'clear-app-state-warning': 'Clear all data and restart from the beginning. Are you sure?',
  'edit-plans-info': 'Plan is not able to be changed on this version. Please proceed to the next step if it\'s acceptable',
  'edit-model-info': 'Delete unnecessary zone if needed. Check the documents for more information',
  'fail-geosimplify': 'Failed to generate simplified plan. Please check model quality referring to documentation',
  'zone-error-alert': 'Detected previous error zone. Please check again',
  'no-model-alert': 'Please import a model',
  'can-not-import-model-alert': 'Can\'t load model. Please check your file.',
  'input-value-alert': 'Please input value',
  'input-number-alert': 'Please input number',
  'input-greater-value-than': 'Please input value greater than ',
  'input-more-than-2-levels': 'Height between levels is recognized as a floor height. Please input more than 2 levels',
  'window-area-must-be-positive-alert': 'Window area must be positive value',
  'wwr-is-too-large-alert': 'Window to wall ratio is too large. Value must be less than 90%',
  'error-on-get-results': 'Error occurred on get results. Do you go back to previous step?',
  // eslint-disable-next-line max-len
  'too-large-model-and-ask-scaling': 'Model is too large to load. You might upload model mm scale. We can scale 1/1000x automatically while this may cause model disruption. Do you want to scale it anyway?',

  'detail-setting': 'Detail Setting',
  'levels-brief': 'Generate simulation model based on level information. Input levels from bottom to top since height between levels is recognized as a floor height.',
  'program': 'program',
  'program-brief': 'Energy condition of time and day of week vary according to program.',
  'space-type': 'space type',
  'space-type-brief': 'Simulation parameters such as lighting level vary according to space type.',
  'wwr': 'Window to wall ratio[%]',
  'wwr-brief': '[Window Area] / [Exterior Wall] x 100[%].',
  'wwr-per-zone': 'per zone',
  'per-wall': 'Input per wall',
  'per-zone': 'Input per zone',
  'learn-more': 'learn more',
  'exterior-wall': 'Exterior wall',
  'change-input-method': 'Change input method',
  'shading': 'Shading[m]',
  'shading-brief': 'Input shading length',

  'back-home': 'Back home',
  'back': 'BACK',
  'run': 'RUN',

  'simulation-prompt': 'Run simulation by following information?',
  'project-summary': 'project summary',
  'floor-numbers': 'Number of Floors',
  'zone-numbers': 'Number of Zones',

  'office': 'Office',
  'residential': 'Residential',
  'retail': 'Retail',
  'school': 'School',
  'common': 'Common',

  'office_open': 'Open office',
  'office_closed': 'Closed office',
  'lift_lobby': 'Lift Lobby',
  'main_lobby': 'Main Lobby',
  'electrical_mechanical_room': 'Electrical Mechanical Room',
  'stairway': 'Stairway',
  'storage': 'Storage',
  'parking_garage': 'Parking Garage',
  'lift': 'Lift',
  'restroom': 'Toilet',
  'corridor': 'Corridor',
  'kitchen': 'Kitchen',
  'bedroom': 'Bedroom',
  'living_room': 'Living room',
  'mall_concourse': 'Mall concourse',
  'restaurant_dining_room': 'Restaurant Dining Space',
  'bar_lounge_cafeteria': 'bar_lounge_cafeteria',
  'sales_area': 'sales_area',
  'classroom': 'classroom',
  'conference_meeting_multipurpose': 'conference_meeting_multipurpose',
  'dormitory': 'dormitory',
  'family_dining_area':'family_dining_area',
  'lounge_recreation':'lounge_recreation',
  'locker_room':'locker_room',
  'patient_room':'patient_room',
  'gymnasium':'gymnasium',
  'food_preparation':'food_preparation',

  'sapporo': 'Sapporo',
  'matsumoto': 'Matsumoto',
  'tokyo': 'Tokyo',
  'nagoya': 'Nagoya',
  'osaka': 'Osaka',
  'miho': 'Miho',
  'tosashimizu': 'Tosashimizu',
  'shimonoseki': 'Shimonoseki',
  'fukuoka': 'Fukuoka',
  'kagoshima': 'Kagoshima',
  'experiment': 'Experiment',

  'wood': 'Wood/Timber',
  'concrete': 'Concrete',
  'steel': 'Steel',
  'traditional': 'Wattle&Daub',
  'brick': 'Bricks',

  'spaceType': 'Space Type',
  'coolingSetPt': 'Cooling Setpoint[degC]',
  'heatingSetPt': 'Heating Setpoint[degC]',
  'pplDensity': 'People Density[ppl/m2]',
  'oaPerson': 'Outdoor Air Requirement per Person[L/ppl]',
  'oaArea': 'Outdoor Air Requirement per Area[L/m2]',
  'smallPower': 'Small Power[W/m2]',
  'lighting': 'Lighting Power Density[W/m2]',
  'coolingCop':'Cooling COP',
  'heatingCop':'Heating COP',
  'efAch': 'Exhaust Fan Air Change per Hour[回/h]',
  'efDeltaPressure': 'Exhaust Fan Delta Pressure[Pa]',

  'envelope': 'Envelope',
  'schedule': 'Schedule',
  'close': 'close',

  'roof':'Roof',
  'exterior-floor':'Exterior Floor',
  'outside':'Outside',
  'inside':'Inside',
  'add-material':'Add Material',

  'window':'Window Performance',
  'uvalue':'U-value',
  'shgc':'Solar Heat Gain Coefficient',
  'vt':'Visible Transmittance',

  'normalConcrete':'Concrete(Normal)',
  'gypsumBoard':'Gypsum Board',
  'plywood':'Plywood',
  "rawanPlywood":'Rawan Plywood',
  'aluminium':'Aluminium',
  'mildSteel':'Mild Steel',
  'stainless':'Stainless',
  'ALC':'ALC',
  'cinderConcrete':'Cinder Concrete',
  'mortar':'Mortar',
  'slateBoard':'Slate Board',
  'siding':'Siding',
  'cementBoard':'Cement Board',
  'stucco':'stucco',
  'soil':'soil',
  'tile':'tile',
  'cedar':'cedar',
  'acrylicBoard':'Acrylic Board',
  'glass':'Glass',

  'polystyreneFoam':'Polystyrene Foam',
  'polyethyleneFoam':'Polyethylene Foam',
  'polyurethaneFoam':'polyurethane Foam',
  'glassWool':'Glass Wool',

  'geometry': 'Geometry',
  'time-series': 'Time Series Data',
  'download': 'Download',
  'hvac': 'HVAC',
  'internal_load': 'Internal load',

  'cancel-simulation': 'cancel simulation',
  'view-results': 'View results',
  'writing': 'Writing simulation data',
  'running': 'Running',
  'completed': 'Completed',

  'january': 'January',
  'february': 'February',
  'march': 'March',
  'april': 'April',
  'may': 'May',
  'june': 'June',
  'july': 'July',
  'august': 'August',
  'september': 'September',
  'october': 'October',
  'november': 'November',
  'december': 'December',

  'total-energy': 'Total Energy',
  'total-energy-consumption': 'Total Energy Consumption',
  'heat-balance': 'Heat Balance',
  'heat-gain': 'Heat Gain',
  'heat-removal': 'Heat Removal',

  'Heating': 'Heating',
  'Cooling': 'Cooling',
  'Interior Lighting': 'Interior Lighting',
  'Exterior Lighting': 'Exterior Lighting',
  'Interior Equipment': 'Interior Equipment',
  'Exterior Equipment': 'Exterior Equipment',
  'Fans': 'Fans',
  'Pump': 'Pump',
  'Ventilation(Simple)': 'Exhaust Fan',

  'Total': 'Total',
  'North (315 to 45 deg)': 'North',
  'East (45 to 135 deg)': 'East',
  'South (135 to 225 deg)': 'South',
  'West (225 to 315 deg)': 'West',
  'Above Ground Wall Area [m2]': 'Above Ground Wall Area [m2]',
  'Above Ground Window-Wall Ratio [%]': 'Above Ground Window-Wall Ratio [%]',

  'Total Site Energy': 'Total',
  'Total Energy [kWh]': 'Total Energy [kWh]',
  'Energy Per Total Building Area [kWh/m2]': 'Energy/Total Building Area [kWh/m2]',
  'Energy Per Conditioned Building Area [kWh/m2]': 'Energy/Conditioned Building Area [kWh/m2]',

  'Facility [Hours]': 'Whole Building',
  'Time Not Comfortable Based on Simple ASHRAE 55-2004': 'Time Not Comfortable Based on Simple ASHRAE 55-2004',
  'Time Setpoint Not Met During Occupied Cooling': 'Unmet Hour During Cooling',
  'Time Setpoint Not Met During Occupied Heating': 'Unmet Hour During Heating',

  'energy_consumption[kWh]': 'Energy Consumption[kWh]',
  'LPD[W/m2]': 'LPD[W/m2]',
  'EUI[kWh/m2]': 'EUI[kWh/m2]',
  'window_heat_gain[W/m2]': 'Window Heat Gain[W/m2]',
  'unmet_hours_cooling[hr]': 'Unmet Hours During Cooling[hr]',
  'unmet_hours_heating[hr]': 'Unmet Hours During Heating[hr]',

  'Window Heat Addition [kWh]': 'Window Heat Gain [kWh]',
  'HVAC Terminal Unit Sensible Air Heating [kWh]': 'HVAC Terminal Unit Heating [kWh]',
  'People Sensible Heat Addition [kWh]': 'People Heat Addition [kWh]',
  'HVAC Input Heated Surface Heating [kWh]': 'HVAC Input Heated Surface Heating [kWh]',
  'Lights Sensible Heat Addition [kWh]': 'Lights Heat Addition [kWh]',
  'Interzone Air Transfer Heat Addition [kWh]': 'Interzone Air Transfer Heat Addition [kWh]',
  'Opaque Surface Conduction and Other Heat Addition [kWh]': 'Opaque Surface Conduction Heat Addition [kWh]',
  'Equipment Sensible Heat Addition [kWh]': 'Equipment Heat Addition [kWh]',
  'HVAC Zone Eq & Other Sensible Air Heating [kWh]': 'HVAC Zone Eq Air Heating [kWh]',
  'Infiltration Heat Addition [kWh]': 'Infiltration Heat Addition [kWh]',

  'Window Heat Removal [kWh]': 'Window Heat Removal [kWh]',
  'HVAC Terminal Unit Sensible Air Cooling [kWh]': 'HVAC Terminal Unit Air Cooling [kWh]',
  'Infiltration Heat Removal [kWh]': 'Infiltration Heat Removal [kWh]',
  'HVAC Input Cooled Surface Cooling [kWh]': 'HVAC Input Cooled Surface Cooling [kWh]',
  'HVAC Zone Eq & Other Sensible Air Cooling [kWh]': 'HVAC Zone Eq Air Cooling [kWh]',
  'Interzone Air Transfer Heat Removal [kWh]': 'Interzone Air Transfer Heat Removal [kWh]',
  'Opaque Surface Conduction and Other Heat Removal [kWh]': 'Opaque Surface Conduction Heat Removal [kWh]',
  'Equipment Sensible Heat Removal [kWh]': 'Equipment Heat Removal [kWh]',

  'select-zone': 'Select zone',
  'summary': 'Summary',
  'colorMap': 'Color Map',
  'zoneDetail': 'Zone Detail',
};

export default en;
